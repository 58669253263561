<!-- https://github.com/nuxt/image -->
<!-- https://nuxt.com/modules/image -->
<!-- https://image.nuxt.com/ -->

<template>
  <div class="relative h-full w-full">
    <div class="absolute inset-0 overflow-hidden 1w-full 1h-full">
      <div :class="[imgZoom]" class="w-full h-full" :style="[`filter:contrast(${imgContrast})`]">
        <NuxtPicture class="w-full h-full"
                     :class="[hvrTransition]"
                     :src="srcEncoded"
                     :loading="loadingStrategy"
                     :alt="alt"
                     :quality="quality"
                     :sizes="sizes"
                     :fit=fit
                     :img-attrs="{ 'class': imgClassname }"
                     :width="width" :height="height"
                     :modifiers="{
                      'max-h': imgHeight,
                      'fp-x': fpX, 'fp-y': fpY,
                      'vib': vibrance,
                      'lossless': 0,
                      'crop': crop,
                    }" />
      </div>

      <div class="z-10 absolute inset-0" :class="[blend]"></div>
    </div>

    <CImageDisclaimer class="z-50" :caption="imgDisclaimer" :layout="imgDisclaimerLayout" :transform="imgDisclaimerTransform" />
  </div>
</template>

<script setup lang="ts">
export interface Props {
  alt?: string,
  blend?: string,
  fit?: 'cover' | 'contain' | 'fill' | 'inside' | 'outside' | 'clamp' | 'clip' | 'crop' | 'facearea' | 'fillMax' | 'max' | 'min' | 'scale',
  crop?: string,
  fpX?: number,
  fpY?: number,
  height?: number,
  imgClassname?: string,
  imgContrast?: number,
  imgDisclaimer?: string,
  imgDisclaimerLayout?: 'default' | 'downRight' | 'lowerRight',
  imgDisclaimerTransform?: 'none' | 'normal',
  imgHeight?: number,
  lazy?: boolean,
  noHoverZoom?: boolean
  preload?: boolean,
  quality?: number,
  vibrance?: number,
  sizes?: string,
  src: string,
  width?: number,
  zoomDirection?: 'in' | 'out' | 'none',
}

const props = withDefaults(defineProps<Props>(), {
  alt: 'Auf den Spuren der Kontinente',
  fit: 'crop',
  crop: 'focalpoint',
  imgContrast: 1.0,
  imgClassname: 'object-cover w-full h-full',
  imgDisclaimerTransform: 'none',
  imgMaxHeight: 400,
  quality: 30,
  height: 250,
  width: 400,
  zoomDirection: 'in',
})
const loadingStrategy = props.lazy ? "lazy" : "eager"

const imgZoom = {
  'in': '[animation:cos-animation-zoom-in_40s_linear_1_0s]',
  'out': '[animation:cos-animation-zoom-out_40s_linear_1_0s]',
  'none': ''
}[props.zoomDirection]

// Sicherstellen, dass eine URL nicht bereits Encoding enthält (z.B. %20)
const srcEncoded = encodeURI(props.src)

// const encodedString = btoa('Hello, World!')
//     .replace(/\+/g, '-')
//     .replace(/\//g, '_')
//     .replace(/=+$/, '');

const hvrTransition = !props.noHoverZoom
  ? '[transition:transform_6s_ease-out] hover:[transform:scale(1.28)] hover:[transition:transform_0.6s_ease-in]'
  : ''
</script>
